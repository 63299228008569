<template>
  <div class="table-container agreements">
    <collection-provider
      collection="distributorAgreements"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(movieId)>
            <dictionary-provider
              name="distributorAgreementMovies"
              ref="distributorAgreementMovies"
            >
              <template #default="{ items: movies, loading: moviesLoading }">
                <multiselect
                  select-label=""
                  track-by="value"
                  label="text"
                  :value="findMovie(movies, data.filter.movieId)"
                  :options="$array.convertToOptions(movies, nullOption)"
                  :close-on-select="true"
                  :searchable="true"
                  :disabled="data.loading || moviesLoading"
                  :show-labels="false"
                  @select="actions.changeFilter('movieId', $event ? $event.value : null)"
                >
                </multiselect>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(cinemas)>
            <dictionary-provider name="cinemas">
              <template #default="{ items: cinemas, loading: cinemasLoading }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="$array.convertToOptions(convertCinemas(cinemas))"
                  :disabled="data.loading || cinemasLoading"
                  @change="actions.changeFilter('cinemaId', $event)"
                ></b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(formats)>
            <dictionary-provider name="formats2">
              <template #default="{ items: formats, loading: formatsLoading }">
                <b-form-select
                  size="sm"
                  :value="data.filter.formatId"
                  :options="$array.convertToOptions(formats)"
                  :disabled="data.loading || formatsLoading"
                  @change="actions.changeFilter('formatId', $event)"
                ></b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #cell(movieId)="data">
            {{data.item.movie.name}}
          </template>
          <template #cell(formats)="data">
            {{$array.convertToText(data.item.formats)}}
          </template>
          <template #cell(cinemas)="data">
            <div
              v-for="cinema in convertCinemas(data.item.cinemas)"
              :key="cinema.id"
            >{{ cinema.name }}</div>
          </template>
          <template #cell(name)="data">
            <div
              class="name"
              :title="data.item.name"
            >{{ data.item.name }}</div>
          </template>
          <template #cell(distributor)="data">
            <div>{{ data.item.movie && data.item.movie.distributor && data.item.movie.distributor.name }}</div>
          </template>
          <template #cell(vat)="data">
            {{data.item.vat ? data.item.vat.name : 'Без НДС'}}
          </template>
          <template #cell(dates)="data">
            <template v-if="data.item.dateStart">
              c {{formatDate(data.item.dateStart)}}
            </template>
            <template v-if="data.item.dateEnd">
              по {{formatDate(data.item.dateEnd)}}
            </template>
          </template>
          <template #cell(original)="data">
            <span v-if="data.item.original">Да</span>
            <span v-else class="text-danger">Нет</span>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.agreement.show"
      create-title="Создание договора"
      edit-title="Редактирование договора"
      collection="distributorAgreements"
      :id="modals.agreement.id"
      :copy="modals.agreement.copy"
      @close="modals.agreement.show = false"
      @saved="$refs.collection.fetchItems(), $refs.distributorAgreementMovies.fetchItems()"
    >
      <template #default="{ data, actions }">
        <agreement-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></agreement-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import AgreementForm from './AgreementForm.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    Datatable,
    AgreementForm,
    Multiselect,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        agreement: {
          show: false,
          id: 0,
          copy: false,
        },
      },
      nullOption: { value: null, text: '- Не выбрано -' },
    };
  },
  created() {
    this.setSort({
      by: 'dateStart',
      desc: true,
    });
  },
  methods: {
    ...mapActions({
      setSort: 'data/distributorAgreements/setSort',
    }),

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateAgreementForm() {
      this.modals.agreement.id = '';
      this.modals.agreement.show = true;
      this.modals.agreement.copy = false;
    },

    async removeCheckedAgreements() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    copyAgreement(id) {
      this.modals.agreement.id = id;
      this.modals.agreement.show = true;
      this.modals.agreement.copy = true;
    },

    openEditAgreementForm(id) {
      this.modals.agreement.id = id;
      this.modals.agreement.show = true;
      this.modals.agreement.copy = false;
    },

    async removeAgreement(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    formatDate(date) {
      return datetime.formatDate(date);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    findMovie(movies, movieId) {
      if (!movies) {
        return this.nullOption;
      }

      const movie = movies.find((_movie) => _movie.id === movieId);

      if (!movie) {
        return this.nullOption;
      }

      return {
        value: movie.id,
        text: movie.name,
      };
    },
  },
};
</script>

<style scoped>
.name {
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
