var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-form-group',{attrs:{"label":"Кинотеатры:","invalid-feedback":_vm.errors.cinemas,"state":_vm.states.cinemas}},[_c('dictionary-provider',{attrs:{"name":"cinemas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cinemas = ref.items;
var cinemasLoading = ref.loading;
return [_c('toggle-list',{attrs:{"options":_vm.convertCinemas(_vm.$array.union(cinemas, _vm.item.cinemas)),"value":_vm.item.cinemas,"disabled":_vm.loading || cinemasLoading},on:{"change":function($event){return _vm.updateValue('cinemas', $event)}}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Фильм:","invalid-feedback":_vm.errors.movieId,"state":_vm.states.movieId}},[_c('dictionary-provider',{attrs:{"name":"movies2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var movies = ref.items;
var moviesLoading = ref.loading;
return [_c('toggle-list',{attrs:{"options":_vm.convertMovies(movies),"value":_vm.item.movie ? [_vm.item.movie] : [],"searchable":true,"disabled":_vm.loading || moviesLoading,"multiple":false,"hide-selected":false,"close-on-select":true},on:{"change":function($event){return _vm.updateMovie($event)}}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Форматы фильмов:","invalid-feedback":_vm.errors.formats,"state":_vm.states.formats}},[_c('toggle-list',{attrs:{"options":_vm.formatsOptions,"value":_vm.item.formats,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('formats', $event)}}})],1),_c('b-form-group',{attrs:{"label":"Номер договора:","invalid-feedback":_vm.errors.name,"state":_vm.states.name}},[_c('b-form-input',{attrs:{"autofocus":"","type":"text","size":"sm","value":_vm.item.name,"state":_vm.states.name,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('name', $event)}}})],1),_c('b-form-group',{attrs:{"label":"Период проката:","invalid-feedback":_vm.errors.dateStart || _vm.errors.dateEnd,"state":_vm.states.dateStart || _vm.states.dateEnd}},[_c('date-interval',{attrs:{"value":{from: _vm.item.dateStart, to: _vm.item.dateEnd},"disabled":_vm.loading},on:{"selected":function($event){_vm.updateValue('dateStart', $event.from), _vm.updateValue('dateEnd', $event.to)}}})],1),_c('b-form-group',{attrs:{"label":"Прокатная плата:","invalid-feedback":_vm.errors.rate,"state":_vm.states.rate}},[_c('b-input-group',{attrs:{"append":"%","size":"sm"}},[_c('b-form-input',{attrs:{"type":"number","size":"sm","min":"0","max":"100","value":_vm.item.rate,"state":_vm.states.rate,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('rate', $event)}}})],1)],1),_c('b-form-group',{attrs:{"label":"НДС:","invalid-feedback":_vm.errors.vat,"state":_vm.states.vat}},[_c('dictionary-provider',{attrs:{"name":"vats"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.vat,"options":_vm.$array.convertToOptions(items, { value: null, text: 'Без НДС' }),"state":_vm.states.vat,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('vat', $event)}}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Сроки оплаты:","invalid-feedback":_vm.errors.paymentTerms,"state":_vm.states.paymentTerms}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","value":_vm.item.paymentTerms,"state":_vm.states.paymentTerms,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('paymentTerms', $event)}}})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.item.original,"state":_vm.states.original,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('original', $event)}}},[_vm._v("Наличие оригинала договора")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }