<template>
  <cinema-page
    route-name="pushkinsCardEvents"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="pushkinsCardEvents"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(movie)="data">
                {{ data.item.movie.name }}
              </template>
              <template #cell(period)="data">
                {{ data.item.movie.name }}
              </template>
            </datatable>
          </template>
        </collection-provider>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../layout/CinemaPage.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },
  },
};
</script>

<style scoped>
</style>
