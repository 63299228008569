var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"priceRates","route-param-name":"cinemaId"},scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
var halls = ref.halls;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"priceRates","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}},scopedSlots:_vm._u([{key:"cell(halls)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$array.convertToText(data.item.halls, 'Все'))+" ")]}},{key:"cell(days)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$array.convertToText(data.item.days, 'Все', 'short'))+" ")]}},{key:"cell(time)",fn:function(data){return [(data.item.timeStart)?_c('span',{staticClass:"mr-1"},[_vm._v("с "+_vm._s(data.item.timeStart))]):_vm._e(),(data.item.timeEnd)?_c('span',[_vm._v("по "+_vm._s(data.item.timeEnd))]):_vm._e()]}},{key:"cell(priceRateValues)",fn:function(data){return _vm._l((data.item.priceRateValues),function(priceRateValue){return _c('div',{key:priceRateValue.id},[_vm._v("Заполняемость "+_vm._s(priceRateValue.occupancy)+"%, изменение цены "+_vm._s(priceRateValue.formula)+" ")])})}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)}),(_vm.modals.priceRate.show)?_c('modal-model-form',{attrs:{"create-title":"Создание ценообразования","edit-title":"Редактирование ценообразования","collection":"priceRates","id":_vm.modals.priceRate.id,"init":{ cinemaId: cinemaId, priceRateValues: [] }},on:{"close":function($event){_vm.modals.priceRate.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('price-rate-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item,"halls":halls},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }