<template>
  <cinema-page
    route-name="productGroups"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="productGroups"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            ></datatable>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.productGroup.show"
          create-title="Создание группы для отображения"
          edit-title="Редактирование группы для отображения"
          collection="productGroups"
          :id="modals.productGroup.id"
          :init="{ cinemaId }"
          @close="modals.productGroup.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <product-group-form
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></product-group-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CinemaPage from '../../layout/CinemaPage.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import ProductGroupForm from './ProductGroupForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    ProductGroupForm,
    ModalModelForm,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        productGroup: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateProductGroupForm() {
      this.modals.productGroup.id = null;
      this.modals.productGroup.show = true;
    },

    openEditProductGroupForm(id) {
      this.modals.productGroup.id = id;
      this.modals.productGroup.show = true;
    },

    async removeProductGroup(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.product-groups {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
