<template>
  <cinema-page
    route-name="equipment"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId, halls }">
      <div>
        <collection-provider
          collection="equipment"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <dictionaries-provider :names="['projectorTypes', 'mediaServerTypes']">
              <template #default="{ projectorTypes, mediaServerTypes }">
                <datatable
                  v-bind="{ ...data, ...actions }"
                  :fields="datatable.fields"
                  :permissions="$user.permissions"
                  :item-actions="datatable.itemActions"
                  :panel-actions="datatable.panelActions"
                  @item-action="performItemAction($event)"
                  @panel-action="performPanelAction($event)"
                >
                  <template #cell(hallId)="data">
                    {{ data.item.hall.name }}
                  </template>
                </datatable>
              </template>
            </dictionaries-provider>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.equipment.show"
          create-title="Создание оборудования"
          edit-title="Редактирование оборудования"
          collection="equipment"
          :id="modals.equipment.id"
          :init="{ cinemaId }"
          @close="modals.equipment.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <equipment-form
              :cinema-id="cinemaId"
              :halls="halls"
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></equipment-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../layout/CinemaPage.vue';

import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import EquipmentForm from './EquipmentForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    EquipmentForm,
    ModalModelForm,
    DictionariesProvider,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        equipment: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateEquipmentForm() {
      this.modals.equipment.id = null;
      this.modals.equipment.show = true;
    },

    openEditEquipmentForm(id) {
      this.modals.equipment.id = id;
      this.modals.equipment.show = true;
    },

    async removeEquipment(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.equipment {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
