<template>
  <div>
    <div class="text-uppercase border-bottom d-flex justify-content-between pb-2 mb-3">
      <div class="align-self-end"></div>
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled"
        @click="addValue()"
      >
        <icon icon="fa-plus"></icon> Добавить заполняемость
      </b-button>
    </div>
    <div>
      <div
        v-for="(value, index) in values"
        :key="index"
        class="border-bottom item-container mb-2"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Заполняемость от, %:"
            >
              <b-form-input
                type="number"
                size="sm"
                autofocus
                :value="value.occupancy"
                :disabled="disabled"
                @change="setValue(index, 'occupancy', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Изменение цены:"
            >
              <price-formula
                :value="value.formula"
                :operations="['-', '+', '=']"
                :disabled="disabled"
                @change="setValue(index, 'formula', $event)"
              ></price-formula>
            </b-form-group>
          </b-col>
        </b-row>
        <span
          class="item-container__trash-button"
          :disabled="disabled"
          @click="removeValue(index)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
    <div
      v-if="!values.length"
      class="text-center"
    >Нет значений</div>
  </div>
</template>

<script>
import PriceFormula from '../../components/fields/PriceFormula.vue';

export default {
  components: {
    PriceFormula,
  },
  props: {
    disabled: {
      default: false,
    },
    values: {
      default: () => [],
    },
  },
  methods: {
    setValue(index, key, value) {
      const copy = [...this.values];

      copy.splice(index, 1, {
        ...copy[index],
        [key]: value,
      });

      this.emitChange(copy);
    },

    removeValue(index) {
      const copy = [...this.values];

      copy.splice(index, 1);

      this.emitChange(copy);
    },

    addValue() {
      this.emitChange([
        ...this.values,
        {
          occupancy: '',
          formula: '',
        },
      ]);
    },

    emitChange(values) {
      this.$emit('change', values);
    },
  },
};
</script>

<style scoped>
.item-container {
  position: relative;
}
.item-container__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
</style>
