export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'halls',
    type: 'text',
    label: 'Залы',
    sortable: false,
    filterable: false,
  },
  {
    key: 'days',
    type: 'text',
    label: 'Дни недели',
    sortable: false,
    filterable: false,
  },
  {
    key: 'time',
    type: 'text',
    label: 'Время',
    sortable: false,
    filterable: false,
  },
  {
    key: 'priceRateValues',
    type: 'text',
    label: 'Изменение цены билета',
    sortable: false,
    filterable: false,
  },
];
