<template>
  <b-input-group>
    <template #prepend>
      <b-dropdown
        size="sm"
        variant="outline-primary"
        no-caret
        :text="formulaOperation"
        :disabled="disabled"
      >
        <b-dropdown-item
          v-for="operation in operations"
          :key="operation"
          @click="changeOperation(operation)"
        >{{operation}}</b-dropdown-item>
        <template #button-content>{{formulaOperation}}</template>
      </b-dropdown>
    </template>
    <b-form-input
      size="sm"
      type="text"
      ref="el"
      :value="formulaValue"
      :disabled="disabled"
      @change="changeValue($event)"
    ></b-form-input>
    <template v-if="resetable" #append>
      <div
        class="d-flex align-items-center h-100 p-1 border border-left-0 rounded-right date-popover__trash cursor-pointer"
        @click="$emit('change', null)"
      >
        <icon icon="fa-times"></icon>
      </div>
    </template>
  </b-input-group>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    operations: {
      default: () => (['-', '=', '+']),
    },
    disabled: {
      default: false,
    },
    resetable: {
      default: false,
    },
  },
  computed: {
    formulaOperation() {
      const firstChar = this.value.charAt(0);

      if (!this.operations.includes(firstChar)) {
        return '=';
      }

      return firstChar;
    },

    formulaValue() {
      const firstChar = this.value.charAt(0);

      if (this.operations.includes(firstChar)) {
        return this.value.slice(1);
      }

      return this.value;
    },
  },
  methods: {
    changeOperation(operation) {
      this.$emit('change', `${operation}${this.formulaValue}`);
    },

    changeValue(value) {
      this.$emit('change', `${this.formulaOperation}${value}`);
    },
  },
};
</script>
