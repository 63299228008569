<template>
  <cinema-page>
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="targets"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(date)="data">
                <span class="mr-1">c {{$datetime.formatDate(data.item.dateStart)}}</span>
                <span>по {{$datetime.formatDate(data.item.dateEnd)}}</span>
              </template>
              <template #cell(ticketsPricePercent)="data">
                {{ data.item.ticketsPricePercent }}%
              </template>
              <template #cell(ticketsCountPercent)="data">
                {{ data.item.ticketsCountPercent }}%
              </template>
            </datatable>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.target.show"
          create-title="Создание плановых показателей"
          edit-title="Редактирование плановых показателей"
          collection="targets"
          :id="modals.target.id"
          :init="{ cinemaId }"
          @close="modals.target.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <target-form
              :cinema-id="cinemaId"
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></target-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>

import CinemaPage from '../../layout/CinemaPage.vue';

import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import TargetForm from './TargetForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    TargetForm,
    ModalModelForm,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        target: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateTargetForm() {
      this.modals.target.id = null;
      this.modals.target.show = true;
    },

    openEditTargetForm(id) {
      this.modals.target.id = id;
      this.modals.target.show = true;
    },

    async removeTarget(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
</style>
