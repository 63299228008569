<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Залы:"
      :invalid-feedback="errors.halls"
      :state="states.halls"
    >
      <toggle-list
        placeholder="Все"
        :options="halls"
        :value="item.halls"
        :disabled="loading"
        @change="updateValue('halls', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Время:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <price-rate-values
      class="mt-3 mb-3"
      :disabled="loading"
      :values="item.priceRateValues"
      @change="updateValue('priceRateValues', $event)"
    ></price-rate-values>
  </b-container>
</template>

<script>
import TimeInterval from '../../components/fields/TimeInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';
import PriceRateValues from './PriceRateValues.vue';

export default {
  components: {
    ToggleList,
    TimeInterval,
    PriceRateValues,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
    halls: {
      default: () => ({}),
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
