<template>
  <cinema-page
    route-name="organizations"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="organizations"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(balance)="data">
                <a
                  class="text-primary cursor-pointer"
                  @click="openOrganizationOperations(data.item)"
                >{{ data.item.balance }}</a>
              </template>
            </datatable>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.organization.show"
          create-title="Создание организации"
          edit-title="Редактирование организации"
          collection="organizations"
          :id="modals.organization.id"
          :init="{ cinemaId }"
          @close="modals.organization.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <organization-form
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></organization-form>
          </template>
        </modal-model-form>

        <b-modal
          v-if="modals.organizationOperations.show"
          centered
          cancel-title="Закрыть"
          size="xl"
          :visible="true"
          :title="modals.organizationOperations.title"
          :ok-only="true"
          ok-title="Закрыть"
          ok-variant="secondary"
          @hidden="closeOrganizationOperations()"
        >
          <organization-operations
            :id="modals.organizationOperations.id"
          ></organization-operations>
        </b-modal>

        <modal-model-form
          v-if="modals.organizationBalance.show"
          id=""
          collection="organizationOperations"
          :create-title="modals.organizationBalance.title"
          :init="{ organizationId: modals.organizationBalance.id }"
          @close="modals.organizationBalance.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <organization-balance-form
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></organization-balance-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CinemaPage from '../../layout/CinemaPage.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import OrganizationForm from './OrganizationForm.vue';
import OrganizationBalanceForm from './OrganizationBalanceForm.vue';
import OrganizationOperations from './OrganizationOperations.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    ModalModelForm,
    OrganizationBalanceForm,
    OrganizationForm,
    OrganizationOperations,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        organization: {
          show: false,
          id: '',
        },
        organizationOperations: {
          show: false,
          id: null,
          title: '',
        },
        organizationBalance: {
          show: false,
          id: null,
          title: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateOrganizationForm() {
      this.modals.organization.id = null;
      this.modals.organization.show = true;
    },

    openEditOrganizationForm(id) {
      this.modals.organization.id = id;
      this.modals.organization.show = true;
    },

    async removeOrganization(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    createTitle({
      name, balance,
    }) {
      return `${name}, Баланс: ${balance}`;
    },

    openOrganizationOperations(item) {
      this.modals.organizationOperations.show = true;
      this.modals.organizationOperations.id = item.id;
      this.modals.organizationOperations.title = this.createTitle(item);
    },

    closeOrganizationOperations() {
      this.modals.organizationOperations.show = false;
      this.modals.organizationOperations.id = null;
      this.modals.organizationOperations.title = '';
    },

    openOrganizationBalanceForm(id, item) {
      this.modals.organizationBalance.show = true;
      this.modals.organizationBalance.id = id;
      this.modals.organizationBalance.title = this.createTitle(item);
    },

    closeOrganizationBalanceForm() {
      this.modals.organizationBalance.show = false;
      this.modals.organizationBalance.id = null;
      this.modals.organizationBalance.title = '';
    },
  },
};
</script>

<style scoped>
.organizations {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
