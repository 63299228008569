<template>
  <cinema-page
    route-name="priceRates"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId, halls }">
      <div>
        <collection-provider
          collection="priceRates"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(halls)="data">
                {{ $array.convertToText(data.item.halls, 'Все') }}
              </template>

              <template #cell(days)="data">
                {{$array.convertToText(data.item.days, 'Все', 'short')}}
              </template>

              <template #cell(time)="data">
                <span v-if="data.item.timeStart" class="mr-1">с  {{data.item.timeStart}}</span>
                <span v-if="data.item.timeEnd">по {{data.item.timeEnd}}</span>
              </template>

              <template #cell(priceRateValues)="data">
                <div v-for="priceRateValue in data.item.priceRateValues" :key="priceRateValue.id">Заполняемость {{ priceRateValue.occupancy }}%, изменение цены {{ priceRateValue.formula }} </div>
              </template>
            </datatable>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.priceRate.show"
          create-title="Создание ценообразования"
          edit-title="Редактирование ценообразования"
          collection="priceRates"
          :id="modals.priceRate.id"
          :init="{ cinemaId, priceRateValues: [] }"
          @close="modals.priceRate.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <price-rate-form
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              :halls="halls"
              @change="actions.setValue($event.field, $event.value)"
            ></price-rate-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CinemaPage from '../../layout/CinemaPage.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PriceRateForm from './PriceRateForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    PriceRateForm,
    ModalModelForm,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        priceRate: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreatePriceRateForm() {
      this.modals.priceRate.id = null;
      this.modals.priceRate.show = true;
    },

    openEditPriceRateForm(id) {
      this.modals.priceRate.id = id;
      this.modals.priceRate.show = true;
    },

    async removePriceRate(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>

</style>
